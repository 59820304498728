import { Button } from 'components/base';
import { ExploreIcon } from 'components/icons';
import Language from 'constants/Language';
import { useUI } from 'providers/UIProvider';
import { useEffect } from 'react';
import { curlyQuotes } from 'utils/text';

interface Props {
  openSectionNav: () => void;
  sectionScrollCTA: string | void;
}

const HomeSidebar: React.FC<Props> = ({ sectionScrollCTA, openSectionNav }) => {
  const { isHighlightSearchVisible } = useUI();

  useEffect(() => {
    const sectionsExplore = document.getElementById('sectionsSidebar');

    if (sectionsExplore) {
      sectionsExplore.style.zIndex = isHighlightSearchVisible ? '675' : '680';
    }
  }, [isHighlightSearchVisible]);

  const handleClick = () => {
    openSectionNav();
  };

  const ctaText = sectionScrollCTA || Language.t('Global.exploreTheScroll');
  return (
    <div
      id="sectionsSidebar"
      className={`HomeSidebar z-sidebar bg-color-beige fixed vh100 t0 r0 none md:block 
        ${isHighlightSearchVisible ? 'events-none' : 'events-all'}`}
    >
      <Button
        ariaLabel={Language.t('Global.openSectionNav')}
        onClick={handleClick}
        className="HomeSidebar__button md:flex vh100 fixed t0 r0 bg-color-beige trigger-ScrollUsage-HomepageDesktopSidebar"
      >
        <div className="HomeSidebar__button__inner flex items-center justify-center">
          <ExploreIcon className="HomeSidebar__icon" color="black" />
          <p className="HomeSidebar__text itc-cushing font-600 uppercase color-red">
            {curlyQuotes(ctaText)}
          </p>
          <span className="HomeSidebar__arrow graebenbach color-red font-400">
            &rarr;&#xFE0E;
          </span>
        </div>
      </Button>
      <Button
        ariaLabel={Language.t('Global.openSectionNav')}
        onClick={handleClick}
        className="HomeSidebar__mock-bar1 z1 vh100 transition fixed t0 r0 bg-color-beige"
      />
      <Button
        ariaLabel={Language.t('Global.openSectionNav')}
        onClick={handleClick}
        className="HomeSidebar__mock-bar2 z2 vh100 transition fixed t0 r0 bg-color-beige"
      />
    </div>
  );
};

export default HomeSidebar;
