import Language from '../constants/Language';
import HomepageJsonLd from '../constants/HomepageJsonLd';
import HomeNav from '../components/HomeNav';
import LayoutModuleSwitch from '../components/LayoutModuleSwitch';
import HomeSidebar from '../components/HomeSidebar';
import ExploreScrollButton from '../components/ExploreScrollButton';
import LatestArticles from '../components/LatestArticles';
import Meta from '../components/Meta';
import { FrontPage, HolidayBanner } from '../sharedTypes';
import { groupArticlesByDate } from '../utils/articles';
import { useSiteSettings } from '../providers/SiteSettingsProvider';
import { useUI } from '../providers/UIProvider';
import React, { useEffect, useMemo } from 'react';
import { hasLatestStoriesFeatureFlag } from 'utils/next';
import { getCookie, setCookie } from 'utils/storage';

interface HomeViewProps {
  frontPage: FrontPage;
  holidayBanner?: HolidayBanner;
}

export const HomeView: React.FC<HomeViewProps> = (props) => {
  const {
    openSectionNav,
    lockScroll,
    unlockScroll,
    showHighlightSearch,
    hideHighlightSearch,
  } = useUI();
  const { globalSettings, latestArticles } = useSiteSettings();
  const { sectionScrollCTA } = globalSettings;
  const { frontPage, holidayBanner } = props;
  const HIGHLIGHT_SEARCH_COOKIE = 'highlightSearchDismissDate';

  const articlesByDate = useMemo(() => {
    if (latestArticles) {
      return groupArticlesByDate(latestArticles);
    }
  }, [latestArticles]);

  useEffect(() => {
    const overlay = document.getElementById('highlightOverlay');
    const callout = document.getElementById('highlightCallout');
    const searchBar = document.getElementById('searchBar');
    const tabletAppButton = document.getElementById('tabletAppButton');
    const HIGHLIGHT_DELAY = 1000;
    const HIGHLIGHT_DURATION = 2500;

    const highlightShown = getCookie(HIGHLIGHT_SEARCH_COOKIE);

    if (!highlightShown) {
      const applyHighlightStyles = () => {
        if (overlay) overlay.style.opacity = '1';
        lockScroll();
        showHighlightSearch();
        if (searchBar) searchBar.classList.add('highlight');
        if (tabletAppButton) tabletAppButton.style.opacity = '0';
        if (callout) {
          callout.textContent = 'Search Tablet’s Archives';
          callout.classList.remove('calloutHidden');
          callout.classList.add('calloutVisible');
        }
      };

      const removeHighlightStyles = () => {
        if (overlay) overlay.style.opacity = '0';
        unlockScroll();
        hideHighlightSearch();
        if (searchBar) searchBar.classList.remove('highlight');
        if (tabletAppButton) tabletAppButton.style.opacity = '1';
        if (callout) {
          callout.classList.remove('calloutVisible');
          callout.classList.add('calloutHidden');
        }
      };

      if (overlay) overlay.addEventListener('click', hideHighlightSearch);

      const showTimeout = setTimeout(() => {
        applyHighlightStyles();

        // Hide the highlight after the duration
        const hideTimeout = setTimeout(
          removeHighlightStyles,
          HIGHLIGHT_DURATION
        );

        // Set the cookie after the highlight is shown to expire in 1 week temporarily
        setCookie(HIGHLIGHT_SEARCH_COOKIE, 'true', 7);

        return () => clearTimeout(hideTimeout);
      }, HIGHLIGHT_DELAY);

      return () => {
        if (overlay) overlay.removeEventListener('click', hideHighlightSearch);
        clearTimeout(showTimeout);
      };
    }
  }, []);

  return (
    <div className="HomeView view content-height relative md:pb1_5">
      <div id="highlightOverlay" />
      <Meta
        title="Tablet Magazine"
        description="A hub of Jewish life, Tablet features news, essays, podcasts, and opinion, covering arts, pop culture, technology, holidays, sports, and more."
        jsonLd={HomepageJsonLd}
      />
      <h1 className="safe-visibility-hidden">{Language.t('Global.slogan')}</h1>
      {frontPage.featuredImage?.src ? (
        <HomeNav
          featuredImage={frontPage.featuredImage}
          featuredLink={frontPage.featuredLink ? frontPage.featuredLink : '/'}
          showTagline={frontPage.showTagline}
          taglineText={frontPage.taglineText}
          showAppButton={frontPage.showAppButton}
        />
      ) : holidayBanner && holidayBanner.id ? (
        <HomeNav
          holidayBanner={holidayBanner}
          showTagline={frontPage.showTagline}
          taglineText={frontPage.taglineText}
          showAppButton={frontPage.showAppButton}
        />
      ) : (
        <HomeNav
          showTagline={frontPage.showTagline}
          taglineText={frontPage.taglineText}
          showAppButton={frontPage.showAppButton}
        />
      )}
      <LayoutModuleSwitch modules={frontPage.modules} />

      {hasLatestStoriesFeatureFlag && articlesByDate ? (
        <LatestArticles articlesByDate={articlesByDate} />
      ) : null}

      <HomeSidebar
        sectionScrollCTA={sectionScrollCTA}
        openSectionNav={openSectionNav}
      />

      <ExploreScrollButton className="md:none" theme="dark" />
    </div>
  );
};
